import { Translated } from "./translated";

export interface EnterprisePreferences {
    enterpriseNumber: string;
    enterpriseName: Translated;
    emails: EboxProfileEmails[];
    firstConnectionDate: Date;
    functionalityActivations: EboxProfileFunctionalities[];
    acknowledgements: EboxProfileAcknowledgements[];
}

export interface EboxProfileEmails {
    emailType?: EmailType;
    /**
     * The e-mail address of the enterprise for communications concerning the e-Box
     */
    email?: string;
    /**
     * Information indicating whether the e-Box has confirmed its mandatary email. By default it's false
     */
    isConfirmed?: boolean;
}

export interface EboxProfileFunctionalities {
    /**
     * Information indicating whether the ebox has activated a certain functionality
     */
    isActivated?: boolean;
    /**
     * The type of e-Box Enterprise's functionality
     */
    code?: FunctionalityCode;
}

export interface EboxProfileAcknowledgements {
    /**
     * Information indicating whether the ebox has acknowledged the new information
     */
    shouldAcknowledge?: boolean;
    /**
     * The type of info that the user of e-Box should acknowledge
     */
    code?: AcknowledgementCode;
}

export enum EmailType {
    ENTERPRISE = 'enterprise',
    MANDATARY = 'mandatary'
};

export enum FunctionalityCode {
    DOC_CONSUMER = 'documentConsumer',
    PARTITION = 'partition',
    OPTIN = 'optin'
}

export enum AcknowledgementCode {
    WORK_INCAPACITY_CERTIFICATE = 'workIncapacityCertificate'
}