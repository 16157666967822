import { ConfigurationService } from '@smals/ngx-configuration-service';
import { Injectable, NgModule } from '@angular/core';
import { HandleExceptionService } from '../error/handle-exception-service.service';
import { Scopes } from '../configuration-data';
import { NgxLoggerLevel } from 'ngx-logger';
import { filter } from 'rxjs/operators';
import { OAUTH_CALLBACK_PATHNAME } from './openIdService';
import { OpenIdEnvironmentConfig } from './open-id-configurator';
import { OpenIdConfiguration, PublicEventsService, EventTypes, LogLevel, StsConfigStaticLoader, AuthModule, StsConfigLoader } from 'angular-auth-oidc-client';


@Injectable({ providedIn: 'root' })
export class AuthOidcClientConfig {



  private _openIdConfig: OpenIdConfiguration;

  constructor(private configService: ConfigurationService, exceptionService: HandleExceptionService, private openIdEventService: PublicEventsService) {

    this.openIdEventService
      .registerForEvents()
      .pipe(filter((notification) => (notification.type === EventTypes.IdTokenExpired || notification.type === EventTypes.TokenExpired)))
      .subscribe((value) => {
        exceptionService.tokenIsExpired()
      });



    this.initConfig();

  }

  initConfig() {
    const env: OpenIdEnvironmentConfig = this.configService.getEnvironmentVariable('openIdEnvironmentConfig');
    if (env === null || env === undefined) {
      throw new Error('Cannot configure Open oidSecurity service without OpenIdEnvironmentConfig parameter for the current environment.');
    }

    this._openIdConfig = {
      configId: 'ebox-enterprise-session-socsec',
      authority: env.stsServer,
      redirectUrl: window.location.protocol + '//' + window.location.host + OAUTH_CALLBACK_PATHNAME,
      clientId: 'documentmanagement:publication:eboxenterprise:client:public',
      scope: 'openid profile ' +
        Scopes.ADMIN + ' ' +
        Scopes.EBOX_PUBLICATION_WEB + ' ' +
        Scopes.EBOX_RESPONSIBLE + ' ' +
        Scopes.EBOX_ENTERPRISE_ADMIN + ' ' +
        Scopes.EBOX_MANDATARY_ADMIN + ' ' +
        Scopes.EBOX_ENTERPRISE_USER + ' ' +
        Scopes.EBOX_MANDATARY_USER
      ,
      responseType: env.responseType,
      silentRenew: true,
      useRefreshToken: true,
      renewTimeBeforeTokenExpiresInSeconds: 10,
      ignoreNonceAfterRefresh: true,
      triggerAuthorizationResultEvent: true,
      postLogoutRedirectUri: env.post_logout_redirect_uri,
      startCheckSession: true,
      postLoginRoute: '/',
      autoUserInfo: env.autoUserInfo,
      renewUserInfoAfterTokenRenew: true,
      autoCleanStateAfterAuthentication: false,
      logLevel: this.convertNgxLoggerLevelToLogLevel(),
      historyCleanupOff: true,
      maxIdTokenIatOffsetAllowedInSeconds:660,
      authWellknownEndpoints: {
        issuer: env.wellKnownEndPoints.issuer,
        jwksUri: env.wellKnownEndPoints.jwks_uri,
        authorizationEndpoint: env.wellKnownEndPoints.authorization_endpoint,
        tokenEndpoint: env.wellKnownEndPoints.token_endpoint,
        userInfoEndpoint: env.wellKnownEndPoints.userinfo_endpoint
      }
    };
  }



  get openIdConfig(): OpenIdConfiguration {
    return this._openIdConfig;
  }

  private convertNgxLoggerLevelToLogLevel(): LogLevel {
    const ngxLoggerLevel: NgxLoggerLevel = this.configService.getEnvironmentVariable('logLevel') as NgxLoggerLevel
    switch (ngxLoggerLevel) {
      case NgxLoggerLevel.DEBUG:
      case NgxLoggerLevel.TRACE:
      case NgxLoggerLevel.DEBUG:
      case NgxLoggerLevel.INFO:
      case NgxLoggerLevel.LOG: return LogLevel.Debug;
      case NgxLoggerLevel.WARN: return LogLevel.Warn;
      case NgxLoggerLevel.ERROR:
      case NgxLoggerLevel.FATAL: return LogLevel.Error;
      case NgxLoggerLevel.OFF:
      default: return LogLevel.None;
    }

  }

}

const authFactory = (openIdClient: AuthOidcClientConfig) => {
  const config = openIdClient.openIdConfig
  return new StsConfigStaticLoader(config);
};

@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: authFactory,
        deps: [AuthOidcClientConfig],
      },
    })],
  exports: [AuthModule]
})
export class AuthConfigModule {
}



