import { Component, Input, OnInit } from '@angular/core';
import { AttachmentData } from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styles: []
})
export class AttachmentComponent implements OnInit {

  private _attachment: AttachmentData;
  public mimetype:string;



	public get attachment(): AttachmentData {
		return this._attachment;
	}

  @Input()
	public set attachment(value: AttachmentData) {
    this._attachment = value;
    this.mimetype = this.getMediaTypeName(value.mediaType);
	}


  constructor() { }


  ngOnInit() {
  }

  private getMediaTypeName(mediaType:string):string{

    switch (mediaType.toLocaleLowerCase()) {
      case "application/pdf": return "-pdf";
      case "application/zip": return "-archive";
      case "text/csv": return "-csv";
      case "text/html":
      case "text/plain": return "-alt";
      case "text/xml":
      case "application/xml": return "-code";

      case "application/vnd.ms-excel":
      case  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case  "application/vnd.oasis.opendocument.spreadsheet": return "-excel";

      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
      case "application/vnd.ms-powerpoint":
      case "application/vnd.oasis.opendocument.presentation": return "-powerpoint";

      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/vnd.oasis.opendocument.text": return "-word";

      case "image/gif":
      case "image/jpeg":
      case "image/png":
      case "application/vnd.oasis.opendocument.graphics": return "-image";


      default:
        return "";
    }


  }

    protected readonly decodeURIComponent = decodeURIComponent;
}
