import { EboxProfileEmails } from "../interfaces/enterprise-preferences";

export interface EnterpriseUser {
    ssin?: string;
    firstName: string;
    lastName: string;
    enterpriseName: string;
    cbeNumber?: string;
    emails: EboxProfileEmails[] | null;
    firstConnectionDate: Date | null;
    lastReminderDate: Date | null;
    exclusivelyEbox: boolean;
    hasAcceptedPartitionSelfServicePolicy: boolean;
    hasAcceptedDocConsumerPolicy: boolean;
    hasAcknowledgedCertificate: boolean | null;
}

export function uniformizeCbeNumber(cbeNumber: string): string {
    return (cbeNumber.length == 9) ? "0" + cbeNumber : cbeNumber;
}
