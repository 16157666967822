<ng-container class="card border-0" [ngSwitch]="currentStep">
    <div
        class="modal-header bg-transparent border-0 p-0 d-flex {{stepEnum[stepEnum.welcome] != currentStep ? ' justify-content-between':'justify-content-end'}} flex-wrap">
        <div class="d-flex justify-content-end w-100 flew-grow-1">
            <div id="langOptin" class="text-nowrap">
                <ul class="list-inline mb-0 me-4">
                    <li *ngFor="let lang of languageSupported"
                        class="list-inline-item">
                        <a [class]="(lang ==language ? 'active' :'')"
                            (click)='setLanguage(lang);'
                            href='javascript:void(0)' [lang]="lang"
                            [title]="('header.language-titles.' + lang) | translate">{{lang}}</a>
                    </li>
                </ul>
            </div>
            <div class="logo align-self-start">
                <img
                    [src]="isMobile ?'assets/images/e-Box_white_mobile.png' :'assets/images/e-Box_white.png'"
                    [width]="isMobile ?'95' :'154'" alt="logo e-Box" />
                <span class="visually-hidden" ngbAutofocus>{{'header.title' | translate}}</span>
            </div>
        </div>
        <h3 class="m-3 pe-0 text-break  d-flex justify-content-start"
            *ngIf="stepEnum[stepEnum.welcome] != currentStep"
            [innerHTML]="'optinFlow.'.concat(currentStep.toString()).concat('.welcomeTitle') |translate">

        </h3>

    </div>
    <div class="modal-body">
        <div class="card border-0 p-0">
            <ng-container *ngSwitchCase="stepEnum[stepEnum.multEmediatt]">
                <ng-container *ngTemplateOutlet="workIncapacityCertificateInfo"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="stepEnum[stepEnum.optin]">
                <ng-container *ngTemplateOutlet="acceptOptin"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="stepEnum[stepEnum.optinReminder]">
                <ng-container *ngTemplateOutlet="acceptOptin"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="stepEnum[stepEnum.email]">
                <ng-container *ngTemplateOutlet="emailValidation"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="stepEnum[stepEnum.welcome]">
                <ng-container *ngTemplateOutlet="welcomeScreen"></ng-container>
            </ng-container>
        </div>
    </div>
    <div class="modal-footer border-0">
        <ul class="stepper mx-auto">
            <li *ngFor="let item of incomeStep; index as i;"
                class=" {{stepEnum[item] == currentStep ? 'active' : ''}} stepper-item">
                <span class="visually-hidden">{{stepEnum[item].toString()}}</span>
            </li>
        </ul>
    </div>
</ng-container>

<ng-template #workIncapacityCertificateInfo>
    <form [formGroup]="updateMultEmediattForm" class="form-inline w-100" name="form" (ngSubmit)="validateMultEmediattAndContinue()">
        <div class="card-body p-0">
            <div [innerHTML]="'optinFlow.multEmediatt.description'.concat(isOptinAccepted() ? '.optinActivated' : '.optinNotActivated') | translate:{ url : multEmediattUrl}"></div>
            <div class="form-style">
                <div class="form-check justify-content-start mb-4">
                    <input type="checkbox" class="form-check-input" name="checkMultEmediatt" id="checkMultEmediatt" formControlName="multEmediatt" (change)="onMultEmediattLabelChange($event)"/>
                    <label for="checkMultEmediatt" class="d-inline-block form-check-label" [innerHTML]="'optinFlow.multEmediatt.checkBoxConsent' | translate"></label>
                </div>
            </div>
            <button class="d-inline-block btn btn-primary" type="submit" [innerHTML]="multEmediattLabel | translate"></button>
        </div>
    </form>
</ng-template>

<ng-template #acceptOptin>

    <form [formGroup]="updateOptinForm" class="form-inline w-100" name="form"
        (ngSubmit)="validateOptinAndContinue()">
        <div class="card-body p-0">

            <div
                [innerHTML]="'optinFlow.'.concat(currentStep.toString()).concat('.description').concat(currentStep.toString() == 'welcome' ?'':(isAdmin()?'.admin':'.user')) | translate: { url: eboxUrl }">
            </div>
            <ng-container *ngIf="isAdmin()">
                <div class="form-style">
                    <div class="form-check justify-content-start mb-4">
                        <input type="checkbox" class="form-check-input"
                            name="checkOptin" id="checkOptin"
                            formControlName="optin" />
                        <label for="checkOptin"
                            class="d-inline-block form-check-label"
                            [innerHTML]="'optinFlow.'.concat(currentStep.toString()).concat('.checkBoxConsent') | translate"></label>
                    </div>
                </div>
                <button class="d-inline-block btn btn-primary" type="submit">{{'optinFlow.'.concat(currentStep.toString()).concat(updateOptinForm.value.optin ? '.validateButton' : '.nextStepButton')|translate}}</button>
            </ng-container>
            <ng-container *ngIf="!isAdmin()">
                <button class="d-inline-block btn btn-primary" type="submit">{{'optinFlow.'.concat(currentStep.toString()).concat( '.nextStepButton')|translate}}</button>
            </ng-container>
        </div>

    </form>

</ng-template>
<ng-template #emailValidation>

    <div class="card-body">
        <app-email-update
            [accessReadOnly]="!isAccessOptinAndEMailModifications()"
            [submitAction]="validateEmailSubject.asObservable()"
            (actionEmitter)="validateMailAndContinue($event)"
            [isOptionFlow]="true"
            class="p-0 d-inline-block"></app-email-update>

        <button (click)="validateEmailSubject.next(true)" type="submit"
            class="d-block mt-3 mt-sm-0 btn btn-primary">{{!isAccessOptinAndEMailModifications() ? ('optinFlow.email.understood' | translate) : ('optinFlow.email.button' | translate)}}</button>
    </div>

</ng-template>

<ng-template #welcomeScreen>

    <div class="card-body p-0 d-flex endFlow">
        <img [src]="'assets/images/optinFlowSuccess.png'" [width]="205" alt
            class="d-none d-sm-block" />
        <div class="flex-grow-1 ">
            <h3 class="me-3 ms-0 mb-3 mt-3 pe-0 text-break">
                {{'optinFlow.'.concat(currentStep.toString()).concat('.welcomeTitle')
                |translate}}
            </h3>
            <div [innerHTML]="'optinFlow.welcome.description' | translate">
            </div>

            <button type="submit" class="d-block  mt-3 mt-sm-0 btn btn-primary"
                (click)="closeModal()">{{'optinFlow.welcome.eboxAccess'
                |translate}}</button>
        </div>

    </div>
</ng-template>
