import { AcknowledgementCode, EboxProfileEmails, EnterprisePreferences, FunctionalityCode } from '../interfaces/enterprise-preferences';
import { OpenIdProfile } from '../auth/openIdService';
import { Alert } from '../interfaces';
import { AuthenticateException, BusinessException, CommonException, ProviderException, TechnicalException } from '../error/CommonException';
import { NGXLogger } from "ngx-logger";
import { ConfigurationService } from '@smals/ngx-configuration-service';
import {
  MessageData,
  ReferenceDataWithRegistry,
  Sender
} from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';
import { Injectable } from '@angular/core';
import { StateFn } from "./StateFn";
import deepEqual from "deep-equal";
import { StoreService } from './store.service';
import {
  AuthenticationState,
  DataForms,
  Folder,
  MessageFilters,
  SearchResults,
  SearchState,
  StatusForms,
  StoreShape,
  VisibilityStatus
} from './store-data-interface';
import { Registry } from '@smals/ebox-enterprise-client/dist/src/external-lib/ServiceClient/RegistriesConfig';
import { MessageRegistry } from "../registries/interface/Message-Registry";
import { EmailsToUpdateEmails } from '../interfaces/emailsToUpdate';

export const defaultPageSize = 10;

export interface SearchAndFilterRequest {
  pageSize?: number
  filters?: Partial<MessageFilters>
  // TODO use a representation that fit the 1 sort order per that this API allows
  folder?: Folder
}



@Injectable({
  providedIn: 'root'
})
export class ActionsStore {




  constructor(private logger: NGXLogger, private _configService: ConfigurationService, private storeService: StoreService, private stateFn: StateFn) {

  }

  // Messages actions

  searchResultUpdated(results: SearchResults) {
    this.storeService.store.next({
      ...this.storeService.store.getValue(),
      searchState: {
        ...this.storeService.store.getValue().searchState,
        searchResults: results
      }
    });
  }

  updateReferencesData(results: ReferenceDataWithRegistry) {
    this.storeService.store.next({
      ...this.storeService.store.getValue(),
      referencesDataState: {
        senderApplicationsState: results.senderApplications,
        messageTypesState: results.messagesTypes,
        senderOrganizationsState: results.senderOrganizations
      }
    });
  }



  switchFolder(newFolder: Folder) {

    const oldStore = this.storeService.store.getValue()

    this.storeService.store.next({
      ...oldStore,
      messageFilters: {
        ...oldStore.messageFilters,
        folder: newFolder,

        visibility: (Folder.out == newFolder) ? undefined : oldStore.messageFilters.visibility,

      },

    });
  }

  changePageSize(pageSize: number) {
    this.storeService.store.next({
      ...this.storeService.store.getValue(),
      searchState: {
        ...this.storeService.store.getValue().searchState,
        pageSize
      }
    });
  }

  // User preferences actions
  checkServicePreferences(isAvailable: boolean) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      subsystems: {
        ...oldStore.subsystems,
        preferences: isAvailable
      }
    });
  }



  updateEnterprisePreferences(enterprisePreferences: EnterprisePreferences, lastRemindDate: Date) {
    let enterpriseName;
    const lang = this.stateFn.getLanguage();
    switch (lang) {
      case 'fr':
        enterpriseName = enterprisePreferences.enterpriseName.fr;
        break;
      case 'nl':
        enterpriseName = enterprisePreferences.enterpriseName.nl;
        break;
      case 'de':
        enterpriseName = enterprisePreferences.enterpriseName.de;
        break;
      default:
        enterpriseName = enterprisePreferences.enterpriseName.fr;
    }

    let exclusivelyEbox:boolean;
    let hasAcceptedPartitionSelfServicePolicy:boolean;
    let hasAcceptedDocConsumerPolicy:boolean;

    enterprisePreferences.functionalityActivations.forEach((functionality) => {
      switch (functionality.code) {
        case FunctionalityCode.OPTIN:
          exclusivelyEbox = functionality.isActivated;
          break;
        case FunctionalityCode.PARTITION:
          hasAcceptedPartitionSelfServicePolicy = functionality.isActivated;
          break;
        case FunctionalityCode.DOC_CONSUMER:
          hasAcceptedDocConsumerPolicy = functionality.isActivated;
          break;
      }
    });

    let hasAcknowledgedCertificate:boolean|null = null;

    enterprisePreferences.acknowledgements.forEach((acknowledgement) => {
      switch (acknowledgement.code) {
        case AcknowledgementCode.WORK_INCAPACITY_CERTIFICATE:
          hasAcknowledgedCertificate = !acknowledgement.shouldAcknowledge;
      }
    })

    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userSession: {
        ...oldStore.userSession,
        user: {
          ...oldStore.userSession.user,
          enterpriseName: enterpriseName,
          emails: enterprisePreferences.emails,
          cbeNumber: enterprisePreferences.enterpriseNumber,
          firstConnectionDate: enterprisePreferences.firstConnectionDate,
          lastReminderDate: lastRemindDate == null ? null : new Date(lastRemindDate),
          hasAcknowledgedCertificate: hasAcknowledgedCertificate,
          exclusivelyEbox: exclusivelyEbox,
          hasAcceptedPartitionSelfServicePolicy: hasAcceptedPartitionSelfServicePolicy,
          hasAcceptedDocConsumerPolicy: hasAcceptedDocConsumerPolicy
        },
      }
    });
    this.checkAlertOptin(this.storeService.store.getValue().userSession.user.exclusivelyEbox);
    this.checkServicePreferences(true);
  }

  updateEmail(emails:  EmailsToUpdateEmails[], newStatusForm: StatusForms) {
    const oldStore = this.storeService.store.getValue()
    const emailsToUpdate:EboxProfileEmails[] = oldStore.userSession.user.emails.map(emailInnerFromStore=>{
      emails.forEach(emailsUpdated =>{
        if(emailsUpdated.emailType == emailInnerFromStore.emailType){
            emailInnerFromStore.email=emailsUpdated.email;
            emailInnerFromStore.isConfirmed = true;
        }

      })
      return emailInnerFromStore;
    });
    this.storeService.store.next({
      ...oldStore,
      userActions: {
        ...oldStore.userActions,
        dataForms: {
          ...oldStore.userActions.dataForms,
          isModeUpdateMail: newStatusForm == StatusForms.success ? false : oldStore.userActions.dataForms.isModeUpdateMail,
          statusForms: newStatusForm,
        }
      },
      userSession: {
        ...oldStore.userSession,
        user: {
          ...oldStore.userSession.user,
          emails:emailsToUpdate
        }
      },
    });
  }

  updateLastRemindDate(lastRemindDate: Date) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userSession: {
        ...oldStore.userSession,
        user: {
          ...oldStore.userSession.user,
          lastReminderDate: lastRemindDate
        }
      }
    });

  }

  updateOptin(optin: boolean) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userSession: {
        ...oldStore.userSession,
        user: {
          ...oldStore.userSession.user,
          exclusivelyEbox: optin
        }
      }
    });
    this.checkAlertOptin(optin);
  }

  updatePartitionPolicy(value: boolean) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userSession: {
        ...oldStore.userSession,
        user: {
          ...oldStore.userSession.user,
          hasAcceptedPartitionSelfServicePolicy: value
        }
      }
    });
  }

  updateDocConsumerPolicy(value: boolean) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userSession: {
        ...oldStore.userSession,
        user: {
          ...oldStore.userSession.user,
          hasAcceptedDocConsumerPolicy: value
        }
      }
    });
  }

  updateConfirmEmail(eboxProfilMail: EboxProfileEmails) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userSession: {
        ...oldStore.userSession,
        user: {
          ...oldStore.userSession.user,
          emails: oldStore.userSession.user.emails.map(eboxMail=>{
            if(eboxMail.emailType == eboxProfilMail.emailType){
              eboxMail.isConfirmed=true;
            }
            return eboxMail;
          })
        }
      }
    });
  }

  updateMultEmediatt(value: boolean) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userSession: {
        ...oldStore.userSession,
        user: {
          ...oldStore.userSession.user,
          hasAcknowledgedCertificate: value
        }
      }
    });
  }

  private checkAlertOptin(optinBoolean) {
    if (this.stateFn.isAccessTokenValid() && this.storeService.store.getValue().userSession.user !== null && this.storeService.store.getValue().userSession.user.exclusivelyEbox !== null) {
      if (this.storeService.store.getValue().userSession.user.exclusivelyEbox) {
        this.removeAlert('warning', 'optin');
      } else {
        this.addAlert('warning', { nameOfResult: 'optin' });
      }
    }

  };

  addScopes(_scopes: string[]) {
    //addWarning for publication web only
    /*
    ** With scope partitionUser we have always access in consultation**
    if (!(_scopes.includes(Scopes.MESSAGES_FULL) && _scopes.includes(Scopes.SUMMARY_OWN_EBOX) && _scopes.includes(Scopes.REFERENCE_DATA)) && _scopes.includes(Scopes.EBOX_PUBLICATION_WEB)){
      this.addAlert('info', { nameOfResult: 'publicationOnly' });
    }*/
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userSession: {
        ...oldStore.userSession,
        scopes: _scopes
      }
    });
  }

  // OIDC actions

  oidcDown(fallbackAccessToken: string) {
    const oldStore = this.storeService.store.getValue()
    this.storeService.store.next({
      ...oldStore,
      tokenIsInvalid: false,
      userSession: {
        ...oldStore.userSession,
        accessToken: fallbackAccessToken,
        configClient: {
          registries: [],
          baseOptions: {
            headers: { 'Authorization': 'Bearer ' + fallbackAccessToken },
            timeoutRequest: this._configService.getEnvironmentVariable('timeoutByRequest')
          }
        },
        typeAuthentication: 'direct'
      },
      subsystems: {
        ...oldStore.subsystems,
        openId: false,
      }
    });
  }

  saveAllMessageRegistries(allRegistries: MessageRegistry[]) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userSession: {
        ...oldStore.userSession,
        allRegistries: allRegistries
      }
    });
  }

  saveRegistries(registriesMerged: { registries: Registry[], useLocalDp: boolean }) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userSession: {
        ...oldStore.userSession,
        configClient: {
          ...oldStore.userSession.configClient,
          registries: registriesMerged.registries
        },
        localDpActive: registriesMerged.useLocalDp
      }
    });
  }
  oidcLoginCompleted(profile: OpenIdProfile, access_token: string) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userSession: {
        ...oldStore.userSession,
        accessToken: access_token,
        configClient: {
          registries: null,
          baseOptions: {
            headers: { 'Authorization': 'Bearer ' + access_token },
            timeoutRequest: this._configService.getEnvironmentVariable('timeoutByRequest')
          }
        },
        typeAuthentication: 'oauth',
        user: {
          ssin: profile.social_security_identification_number,
          firstName: profile.given_name,
          lastName: profile.family_name,
          cbeNumber: profile.enterprise_number.length == 9 ? '0'+profile.enterprise_number : profile.enterprise_number,
          enterpriseName: '',
          emails: [],
          firstConnectionDate: null,
          lastReminderDate: null,
          exclusivelyEbox: false,
          hasAcceptedPartitionSelfServicePolicy: false,
          hasAcceptedDocConsumerPolicy: false,
          hasAcknowledgedCertificate: false
        },
      }
    })
  }

  oidcUpdateAccessToken(access_token: string) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      tokenIsExpired: false,
      userSession: {
        ...oldStore.userSession,
        accessToken: access_token,
        configClient: {
          ...oldStore.userSession.configClient,
          baseOptions: {
            timeoutRequest: this._configService.getEnvironmentVariable('timeoutByRequest'),
            headers: { 'Authorization': 'Bearer ' + access_token },
          }
        }
      }
    })
  }

  removeError() {
    const oldStore = this.storeService.store.getValue()
    this.storeService.store.next({
      ...oldStore,
      userSession: {
        ...oldStore.userSession
      },
      subsystems: {
        ...oldStore.subsystems,
        error: null
      },
      tokenIsInvalid: false
    });
  }

  changeLanguage(language: string) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userSession: {
        ...oldStore.userSession,
        localeParameter: language
      }
    })
  }

  // Alerts actions
  addAlerts(alertType: string, alerts: Alert[]) {
    alerts.forEach(a => {
      this.updateAlert(alertType, a);
    });
  }

  // Alerts actions
  addException(alertType: string, alerts: CommonException[]) {
    alerts.forEach(a => {
      this.updateException(alertType, a);
    });
  }



  addAlert(alertType: string, alert: Alert | string | CommonException) {
    if (typeof alert === 'string') {


      this.alerts[alertType] = alert;
    } if (alert instanceof BusinessException || alert instanceof TechnicalException || alert instanceof AuthenticateException || alert instanceof CommonException || alert instanceof ProviderException) {
      this.updateException(alertType, alert as CommonException);

    } else {
      this.updateAlert(alertType, alert as Alert);
    }
    //store.next(store.getValue());
  }


  private updateException(alertType: string, exception: CommonException) {
    const exceptionStore: Record<string, Alert[] | string | CommonException | CommonException[]> = this.alerts as Record<string, Alert[] | string | CommonException | CommonException[]>;
    let exceptionValue;

    //no error exist in store
    if (exceptionStore[alertType] !== undefined) {
      exceptionValue = exceptionStore[alertType];
    }
    if (!exceptionValue) {
      //create error and initialization with param exception in store
      exceptionValue = new Array(exception);
      exceptionStore[alertType] = exceptionValue;
    } else if (!(exceptionValue instanceof Array)) {
      //transform the simple exception to avoid a array of Exception with 1 element with old exception
      exceptionValue = new Array(exceptionValue[alertType]);
    }


    const commonException: CommonException[] = (exceptionValue as CommonException[]).filter(value => value.type == exception.type);
    if (commonException.length == 0) {
      (exceptionValue as CommonException[]).unshift(exception);
    } else if (exception instanceof ProviderException) {
      const providerException = (commonException).filter((cException) => cException instanceof ProviderException).find(
        (pException: ProviderException) => pException.providerId == (exception as ProviderException).providerId);
      if (providerException == undefined) {
        (exceptionValue as CommonException[]).unshift(exception);
      }

    }

  }
  private updateAlert(alertType: string, alert: Alert) {
    const alertStore: Record<string, Alert[] | string | CommonException | CommonException[]> = this.alerts as Record<string, Alert[] | string | CommonException | CommonException[]>;
    let alertValue;
    if (alertStore[alertType] !== undefined) {
      alertValue = alertStore[alertType];
    }

    if (!alertValue) {
      alertValue = new Array<Alert>();
      if (alert.count !== 0) alertStore[alertType] = alertValue;
    }

    let alertInMap: Alert;
    alertValue.forEach((a: Alert) => {
      if (alert.nameOfResult === a.nameOfResult) {
        alertInMap = a;
      }
    });

    if (alertInMap) {

      if (alert.count == 0) {
        this.removeAlert(alertType, alertInMap.nameOfResult);
      } else {
        alertInMap.count = alert.count;
      }
    } else if (alert.count !== 0) {
      alertValue.push(alert);
    }
    this.storeService.store.next(this.storeService.store.getValue());
  }

  removeAlert(alertType: string, nameOfResult?: string) {
    if (this.alerts[alertType] !== undefined) {
      /*before const alertAny: any = this.alerts.get(alertType);

       if(typeof alertAny !== 'string' && !(alertAny instanceof BusinessException || alertAny instanceof TechnicalException || alertAny instanceof AuthenticateException)) */

      if (Array.isArray(this.alerts[alertType])) {
        const alertArray = this.alerts[alertType] as Alert[];
        if (nameOfResult == undefined) {
          delete this.alerts[alertType];
          this.storeService.store.next(this.storeService.store.getValue());

        } else {
          alertArray.forEach((alert, index) => {
            if (alert.nameOfResult === nameOfResult) {
              alertArray.splice(index, 1);
              if (alertArray.length == 0) delete this.alerts[alertType];
              this.storeService.store.next(this.storeService.store.getValue());
              return;
            }
          });
        }

      } else {
        delete this.alerts[alertType];
        this.storeService.store.next(this.storeService.store.getValue());
      }
    }
  }

  nextStepAuthentication(authenticationState: AuthenticationState) { // static
    this.logger.debug('newStep Authentication %s', authenticationState);
    const oldStore = this.storeService.store.getValue()
    this.storeService.store.next({
      ...oldStore,
      userSession: {
        ...oldStore.userSession,
        stepAuthentication: authenticationState
      },
    });
  }

  // Getters and setters

  public get alerts(): Record<string, Alert[] | string | CommonException | CommonException[]> {
    return this.storeService.store.getValue().alerts;
  }


  deprecated_clearMessageFilters() {
    const state = this.storeService.store.getValue();
    this.storeService.store.next({
      ...state,
      messageFilters: this.clearedFilters(),
    })
  }

  clearSearchAndMessageFiltersAndPage() {
    const state = this.storeService.store.getValue();
    this.storeService.store.next({
      ...state,
      messageFilters: this.clearedFilters(),
      searchState: this.clearedSearchState(),
    })
  }

  startNewSearch(terms: string) {
    const state = this.storeService.store.getValue();
    this.storeService.store.next({
      ...state,
      messageFilters: {
        ...this.clearedFilters(),
        folder: state.messageFilters.folder,
        visibility: state.messageFilters.visibility,
        recipientPartition: state.messageFilters.recipientPartition,
        senderPartition: state.messageFilters.senderPartition,
        q: terms
      },
      searchState: this.clearedSearchState(),
    })
  }

  filterMessages(filters: Partial<MessageFilters>) {
    const state = this.storeService.store.getValue();
    this.storeService.store.next({
      ...this.storeService.store.getValue(),
      messageFilters: {
        ...state.messageFilters,
        ...filters,
      },
      searchState: {
        //resultLoadingState:DataLoadingState.unloaded,
        ...state.searchState,
        pageSize: 10,
      }
    });

  }

  setMobileDevice(isMobile: boolean) {
    const state = this.storeService.store.getValue();
    this.storeService.store.next({
      ...state,
      isMobile: isMobile,
    })
  }

  loadSearchParamsFromNavBar(params: SearchAndFilterRequest) {
    const state = this.storeService.store.getValue();

    const newState = {
      ...state,
      messageFilters: this.clearedFilters(),
      searchState: this.clearedSearchState(),
    };
    if (params.filters != null) {

      newState.messageFilters = {
        ...newState.messageFilters,
        ...params.filters
      }
    }

    if (params.pageSize != null) {
      newState.searchState.pageSize = params.pageSize;
    }

    // Do not update the state if it is the same as the current state
    if (!deepEqual(state, newState)) {
      this.storeService.store.next(newState)
    }
  }

  showMaskedMessages() {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userSession: {
        ...oldStore.userSession
      },

      messageFilters: {
        ...this.clearedFilters(),
        visibility: VisibilityStatus.invisible,
        folder: null
      },
      searchState: this.clearedSearchState(),
    })
  }

  showExpiringMessages() {
    const state = this.storeService.store.getValue();
    this.storeService.store.next({
      ...state,
      messageFilters: {
        ...this.clearedFilters(),
        includeExpiringMessages: true,
        includeUnreadMessages: true,
      },
      searchState: this.clearedSearchState(),
    })
  }

  updateStoreReadStatus(message: MessageData): MessageData {
    const oldStore = this.storeService.store.getValue();
    let messageRead: MessageData = null;
    const searchResultsItems = oldStore.searchState.searchResults;
    searchResultsItems.items.forEach((item: MessageData) => {
      if (item.messageId == message.messageId && item.registryId === message.registryId) {
        item.statusMessageData.readStatus = true;
        messageRead = item;
        return;
      }
    });
    this.storeService.store.next({
      ...oldStore,
      searchState: {
        ...oldStore.searchState,
        searchResults: searchResultsItems
      }
    });
    return messageRead;
  }

  updateCurrentPage(currentPage: string) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userActions: {
        ...oldStore.userActions,
        currentPage: currentPage
      }
    })

  }

  updateMessagesSelected(selections: string[]) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userActions: {
        ...oldStore.userActions,
        messagesSelected: selections
      }
    })
  }

  updateNbMessagesActionRequired(nbAction: number) {
    const oldStore = this.storeService.store.getValue() as StoreShape;
    this.storeService.store.next({
      ...oldStore,
      searchState: {
        ...oldStore.searchState,
        searchResults: {
          ...oldStore.searchState.searchResults,
          totalItemsMessageAction: nbAction
        }
      }
    })
  }

  showRegisteredMessages() {
    const state = this.storeService.store.getValue();
    this.storeService.store.next({
      ...state,
      messageFilters: {
        ...this.clearedFilters(),
        includeRegisteredMessages: true,
        includeUnreadMessages: true,
      },
      searchState: this.clearedSearchState(),
    })
  }



  // UserActions Interaction

  updateStatusReplyForms(newStatusForm: StatusForms) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userActions: {
        ...oldStore.userActions,
        dataForms: {
          ...oldStore.userActions.dataForms,
          isModeReply: newStatusForm == StatusForms.success ? false : oldStore.userActions.dataForms.isModeReply,
          statusForms: newStatusForm
        }
      }
    });
  }
  updateModeReply(toggleReply: boolean, sender: Sender) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userActions: {
        ...oldStore.userActions,
        dataForms: {
          ...oldStore.userActions.dataForms,
          isModeReply: toggleReply,
          sender: sender
        }
      }
    });

  }
  clearStatusForms() {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userActions: {
        ...oldStore.userActions,
        dataForms: this.clearedReplyAndManualForms()
      }
    });

  }
  updateStatusManualForms(newStatusForm: StatusForms, recipient: Sender) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userActions: {
        ...oldStore.userActions,
        dataForms: {
          ...oldStore.userActions.dataForms,
          isModeReply: false,
          isModeManual: newStatusForm == StatusForms.success ? false : oldStore.userActions.dataForms.isModeManual,
          statusForms: newStatusForm,
          sender: recipient

        }
      }
    });
  }
  updateModeManual(toggleManual: boolean, sender: Sender) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userActions: {
        ...oldStore.userActions,
        dataForms: {
          ...oldStore.userActions.dataForms,
          isModeManual: toggleManual,
          sender: sender
        }
      }
    });

  }

  updateStatusMoveMessage(newStatusForm: StatusForms, _partitionId: string) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userActions: {
        ...oldStore.userActions,
        dataForms: {
          ...oldStore.userActions.dataForms,
          isModeReply: false,
          isModeManual: false,
          isModeMoveMessage: newStatusForm == StatusForms.success ? false : oldStore.userActions.dataForms.isModeMoveMessage,
          statusForms: newStatusForm,
          partitionId: _partitionId
        }
      }
    });
  }
  updateMoveMessage(toggleMoveMessage: boolean) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userActions: {
        ...oldStore.userActions,
        dataForms: {
          ...oldStore.userActions.dataForms,
          isModeMoveMessage: toggleMoveMessage
        }
      }
    });

  }

  updateStatusMailForms(newStatusForm: StatusForms) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userActions: {
        ...oldStore.userActions,
        dataForms: {
          ...oldStore.userActions.dataForms,
          isModeUpdateMail: newStatusForm == StatusForms.success ? false : oldStore.userActions.dataForms.isModeUpdateMail,
          statusForms: newStatusForm
        }
      }
    });
  }

  updateModeMail(toggleMail: boolean) {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userActions: {
        ...oldStore.userActions,
        dataForms: {
          ...oldStore.userActions.dataForms,
          isModeUpdateMail: toggleMail,
          //statusForms: (!toggleMail)? null : oldStore.userActions.dataForms.statusForms
        }
      }
    });

  }


  // TODO These initial state creation methods should be used for the initial state creation also and not only for the clear state
  clearedReplyAndManualForms(): DataForms {
    return { isModeReply: false, isModeManual: false, statusForms: null, isModeUpdateMail: false, isModeMoveMessage: false, sender: null, partitionId: null } as DataForms;
  }

  clearedFilters(): MessageFilters {
    return {
      q: null,
      visibility: VisibilityStatus.visible,
      includeUnreadMessages: false,
      includeReadMessages: false,
      includeRegisteredMessages: false,
      includeExpiringMessages: false,
      senderOrganizationSelection: null,
      senderApplicationSelection: null,
      publicationDateFrom: null,
      publicationDateTo: null,
      folder: Folder.in,
      recipientPartition: null,
      senderPartition: null,
      includeRequiredAction: null
    }
  }



  clearedMessagesSelected() {
    const oldStore = this.storeService.store.getValue();
    this.storeService.store.next({
      ...oldStore,
      userActions: {
        ...oldStore.userActions,
        messagesSelected: []
      },
    });

  }

  clearedSearchState(): SearchState {
    return {
      //resultLoadingState:DataLoadingState.unloaded,
      pageSize: this.stateFn.getSearchResultPageSize(),
      searchResults: null,
    }
  }



}

