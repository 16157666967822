import { Injectable } from "@angular/core";
import { ApplicationConstants } from "../app.constants";
import { AbstractControl, UntypedFormControl, ValidatorFn, Validators } from "@angular/forms";
import { MessageToPublish } from "../interfaces/message-to-publish";
import { Attachment } from "../interfaces/legacy-reply-to-message";
import { EnterpriseUser } from "../models/enterprise-user";
import { HttpErrorResponse } from "@angular/common/http";
import { EnterpriseNumber, Ssin } from "@smals/ngx-smalsutils-validation";
import { StatusForms } from "../store/store-data-interface";
import { UserPartitionForm } from "../pacman/interfaces/userPartitionForm";
import { PacmanProblem } from "../pacman/interfaces/pacmanProblem";
import { MessageToReply } from "../interfaces/message-to-reply";
import { StructuredData } from "../interfaces/structured-data";
import { EboxProfileEmails, EmailType } from "../interfaces/enterprise-preferences";
import { StateFn } from "../store/StateFn";


@Injectable()
export class FormsHelper {
  constructor() { }

  static readonly regexpCompanyNumber = new RegExp(/[\W\s]|_/g);
  static readonly regexpSsinNumber = new RegExp(/[\W\s]|_/g);



  static companyNumberValidator(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } | null => {
      let validationResult = null;
      if (!(control.value === '' || control.value === undefined || control.value === null)) {
        const valueCleanup = control.value.replace(this.regexpCompanyNumber, "");
        // create a temp to avoid loop validation when value change
        const tempControl = new UntypedFormControl(valueCleanup, [Validators.required]);

        const resultCheckBySmals: { range?: boolean, checksum?: boolean } = EnterpriseNumber.checkPattern(tempControl);
        if (resultCheckBySmals != null) {
          if (resultCheckBySmals.range != undefined || resultCheckBySmals.checksum != undefined) {
            validationResult = { 'destinationEnterpriseError': 'errorFormat' };
          }
        } else if (tempControl.value.length != 10) {
          validationResult = { 'destinationEnterpriseError': 'errorFormat' };
        }
      }
      return validationResult;
    };
  }

  static NissNumberValidator(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } | null => {
      let validationResult = null;
      if (!(control.value === '' || control.value === undefined || control.value === null)) {
        const valueCleanup = (control.value instanceof Object ? control.value["id"] : control.value).replace(this.regexpSsinNumber, "");
        // create a temp to avoid loop validation when value change
        const tempControl = new UntypedFormControl(valueCleanup, [Validators.required]);

        const resultCheckBySmals: { range?: boolean, checksum?: boolean } = Ssin.checkPattern(tempControl);
        if (resultCheckBySmals != null) {
          if (resultCheckBySmals.range != undefined || resultCheckBySmals.checksum != undefined) {
            validationResult = { 'userNissError': 'errorFormat' };
          }
        } else if (tempControl.value.length != 11) {
          validationResult = { 'userNissError': 'errorFormat' };
        }
      }
      return validationResult;
    };
  }



  static requiredAndTrimValueValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => (control.value == null || control.value.length === 0 || control.value.trim().length === 0) ? { 'required': true } : null
  }

  static phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

      if (control.value === '' || control.value === undefined || control.value === null) {
        return null;
      }
      const phoneNumber = control.value;
      let number = null;
      let error: boolean = null;
      if (phoneNumber.startsWith('+32')) {
        number = '0' + phoneNumber.substring(3).replace(/\D/g, '');
      } else if (phoneNumber.startsWith('0032')) {
        number = '0' + phoneNumber.substring(4).replace(/\D/g, '');
      } else if (phoneNumber.startsWith('00')) {
        number = '+' + phoneNumber.substring(2).replace(/\D/g, '');
      } else if (phoneNumber.startsWith('0')) {
        number = phoneNumber.replace(/\D/g, '');
      } else {
        number = '+' + phoneNumber.replace(/\D/g, '');
      }
      if (phoneNumber.match(/[a-z]/i)) {
        error = true;
      } else if (number.startsWith('0') && number.length < 9 || number.length > 10) {
        error = true;

      } else if (number.startsWith('+') && number.length < 10 || number.length > 11) {
        error = true;
      } else if (!ApplicationConstants.TELNUMBERPATTERN.test(phoneNumber)) {
        error = true;
      }
      if (error) {
        return { 'phoneNumberError': 'errorFormat' };
      }

      return null;
    };
  }

  static toFormData(_messageToPublish: MessageToPublish, _attachments: Attachment[], _enterpriseUser: EnterpriseUser): FormData {
    const formData: FormData = new FormData();
    if (_enterpriseUser != null) {
      _messageToPublish.enterpriseInfo = {
        denomination: this.prepareModeDegrade(_enterpriseUser.enterpriseName), mailAddress: this.prepareModeDegrade(_enterpriseUser.emails)
      };
      _messageToPublish.userInfo = { firstName: this.prepareModeDegrade(_enterpriseUser.firstName), lastName: this.prepareModeDegrade(_enterpriseUser.lastName), nrn: this.prepareModeDegrade(_enterpriseUser.ssin) };
    }
    if (_attachments != undefined && _attachments.length > 0) {
      _messageToPublish.attachments = [];
      _attachments.forEach(attachment => {
        _messageToPublish.attachments.push({ isAttachmentSigned: false, httpPartName: attachment.fileName, isMainContent: false });
        //hack ie11 add three parts for the fileName
        formData.append(encodeURIComponent(attachment.fileName), new Blob([attachment.content], { type: attachment.mediaType }), encodeURIComponent(attachment.fileName));
      })

    }

    const stringifyBidi = JSON.stringify(_messageToPublish);
    formData.append('messageToPublish', stringifyBidi);


    return formData;
  }

  static toFormReplyData(_messageToReply: MessageToReply, _structuredData: StructuredData, _attachments: Attachment[], _enterpriseUser: EnterpriseUser, _stateFn: StateFn): FormData {
    const formData: FormData = new FormData();
    if (_enterpriseUser != null) {
      _structuredData["enterpriseInfo"] = {
        denomination: this.prepareModeDegrade(_enterpriseUser.enterpriseName), mailAddress: this.prepareEmail(_enterpriseUser.emails, _stateFn)
      };
      _structuredData["userInfo"] = { firstName: this.prepareModeDegrade(_enterpriseUser.firstName), lastName: this.prepareModeDegrade(_enterpriseUser.lastName), nrn: this.prepareModeDegrade(_enterpriseUser.ssin) };
    }
    if (_attachments != undefined && _attachments.length > 0) {
      _messageToReply.attachments = [];
      _attachments.forEach(attachment => {
        _messageToReply.attachments.push({ isAttachmentSigned: false, httpPartName: attachment.fileName, isMainContent: false });
        //hack ie11 add three parts for the fileName
        formData.append(encodeURIComponent(attachment.fileName), new Blob([attachment.content], { type: attachment.mediaType }), encodeURIComponent(attachment.fileName));
      })
    }
    _messageToReply.structuredData = JSON.stringify(_structuredData);
    const stringifyBidi = JSON.stringify(_messageToReply);
    formData.append('messageToReply', stringifyBidi);


    return formData;
  }

  static toRawData(_userPartitionForm: UserPartitionForm) {
    const userPartitionRightToCreate = {
      user: {
        ssin: _userPartitionForm.user.id,
      }
    };
    return JSON.stringify(userPartitionRightToCreate);
  }

  static handleSendErrorForms(error: HttpErrorResponse, statusSendingForm: StatusForms) {
    const httpError: HttpErrorResponse = error as HttpErrorResponse;
    let errors: { typeOfError: string, args?: [string] } = null;

    switch (httpError.status) {
      case 413:
        errors = { typeOfError: 'maxSize', args: [this.extractFileNameFromHttpStatusError(httpError.error.message, "File size is too large:")] }
        break;

      case 415:
        errors = { typeOfError: 'mimetype' }
        break;

      case 400:
        switch (httpError.error.code) {
          case "INVALID_INPUT":
            if (httpError.error["message"] != undefined) {
              if (httpError.error.message.indexOf('Attachment mime type is not valid') != -1) {
                errors = { typeOfError: 'mimetype' }
                break;
              }
              if (httpError.error.message.indexOf('Umanregistry: INVALID_INPUT') != -1) {
                errors = { typeOfError: 'mailInvalid' }
                break;
              }
            }
            errors = { typeOfError: 'error' };
            statusSendingForm = StatusForms.failed;
            break;
          default: errors = { typeOfError: 'empty' }

            break;

        }
        break;


      default: statusSendingForm = StatusForms.failed;
        break;
    }

    return { status: statusSendingForm, errorOBj: errors };

  }

  static handlePacmanErrorForms(error: HttpErrorResponse, statusSendingForm: StatusForms) {
    const httpError: HttpErrorResponse = error as HttpErrorResponse;
    if (error.status == 0 && error.statusText == 'Unknown Error') {
      const errorWS = { type: 'generalError' };
      return { status: StatusForms.failed, errorOBj: errorWS };

    }
    const pacmanError: PacmanProblem = httpError.error as PacmanProblem;
    const regex = new RegExp("^[http:|https:]");
    if (pacmanError.type != null) {
      if (regex.test(pacmanError.type.toString())) {
        pacmanError.type = pacmanError.type.substring(pacmanError.type.lastIndexOf('/') + 1);
      } else {
        pacmanError.type = pacmanError.type.substring(pacmanError.type.lastIndexOf(':') + 1);

      }
    }


    return { status: statusSendingForm, errorOBj: httpError.error as PacmanProblem };

  }

  private static prepareEmail(eboxProfileEmails: EboxProfileEmails[], _stateFn: StateFn) {
    let email: string;
    if (eboxProfileEmails.length == 1) {
      email = eboxProfileEmails[0].email;
    } else {
      const emailSearch = eboxProfileEmails.find(eboxProfileEmail => ((_stateFn.isRae() || _stateFn.isEnterpriseUser()) && EmailType.ENTERPRISE == eboxProfileEmail.emailType) || (_stateFn.isMandatary() && EmailType.MANDATARY == eboxProfileEmail.emailType && !_stateFn.isRae()));
      if (emailSearch !== undefined) {
        email = emailSearch.email
      }

    }
    return this.prepareModeDegrade(email)

  }

  private static prepareModeDegrade(value: any) {

    return ((value == null) ? " " : value);

  }

  private static extractFileNameFromHttpStatusError(message: string, errorTemplate: string): string {
    const extractFileName = (message.replace(errorTemplate, "")).trim();
    return extractFileName;
  }

  static fillErrors(errors: [{ typeOfError: string, args?: [string | number] }], typeOfErrorCurr: string, argsCurr: string | number): [{ typeOfError: string, args?: [string | number] }] {
    let completeFill = false;
    if (errors != null) {
      errors.forEach((error: { typeOfError: string, args?: [string | number] }) => {
        if (error.typeOfError == typeOfErrorCurr && argsCurr != null) {
          error.args.push(argsCurr);
          completeFill = true;
          return;
        }
      });
      if (!completeFill) {
        errors.push({ typeOfError: typeOfErrorCurr, args: [argsCurr] });
      }
    } else {
      errors = [{ typeOfError: typeOfErrorCurr, args: [argsCurr] }]
    }

    return errors;
  }



}
